<template>
<v-container style="background-image: url('src/img/docs.jpg'),linear-gradient(106deg, #0093E9 0%, #80D0C7 80%); height:100vh" fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex sm12 md6>
            <v-img
            class="onboarding-image"
            max-height="200px"
            style="background-size: cover;"
            dark
            gradient="to right, #00000030, #ffffff00, #ffffff00"
            src="../img/haem.jpg"
            ><v-subheader
              class=""
              style="
                position: absolute;
                left: 1rem;
                bottom: 1rem !important;
                color: white !important;
                font-size: 2.2rem;
                font-weight: 400;
              "
              >404 – Seite nicht gefunden</v-subheader
            ></v-img>
            
            <v-card style="background:white!important;" elevated="1" flat class="pa-3">
              <v-card-text>
                <p class="subheader">Leider exsistiert die gesuchte Seite nicht (mehr).<br>Klicken Sie auf die Schaltfläche, um zurück zum Dashboard zu gelangen.</p>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  tile
                  to="/"
                  class="mx-2 px-10 py-6 mb-3"
                  style="color:white!important;box-shadow:none!important;"
                  color="black"
                  >Zurück</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style scoped lang="scss">
.subheader {
  font-size: 1.15rem;
  color:black!important;
    font-weight: 300;
    line-height: 1.3672;
    letter-spacing: 0;
    margin-bottom: 0;
}

</style>
